import * as React from "react";

import Layout from "../components/layout/layout";
import ProductPlatformComponent from "../components/product-page/product_platform";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const ProductPlatform = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title={t('platform')}
        description={t('evaluate_your_candidates_in_the_easiest_way')}
      />
      <Layout>
        <ProductPlatformComponent />  
      </Layout>
    </>
  )
}

export default ProductPlatform
