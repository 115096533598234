import React from "react";
import { Link } from "gatsby";
import { withTranslation } from 'react-i18next';

import * as classes from "./ready.module.scss";

const Ready = ({t,
  title = t('ready_to_test_mereos'),
  description = t('discover_our_ready_to_use_solutions'),
  leftButton = {
    href: "/free-trial",
    text: t('register'),
  },
  rightButton = {
    href: "/free-trial",
    text: t('learn_more'),
  },
  image = "https://d2lxkizvrhu4im.cloudfront.net/images/Market_launch.svg"
}) => {
  return (
    <>
      <div className=" lg:pl-10  w-full  flex flex-col md:flex-row items-center justify-between bg-gradient-to-r from-yellow-500 to-yellow-400 rounded-lg font-sans">
        <div className="flex flex-col space-y-4 p-4 lg:space-y-7 md:text-left items-center justify-start lg:items-start lg:w-6/12  ">
          <div className={`text-white w-full text-3xl lg:text-5xl  leading-loose font-semibold ${classes.title}`}>
            {title}
          </div>
          <div
            style={{ textAlign: "justify" }}
            className={`text-white text-sm md:text-lg font-medium  text-left lg:w-8/12 ${classes.description}`}
          >
            {description}
          </div>
          <div className="flex flex-row w-full justify-between md:justify-start">
            <Link to={leftButton.href}>
              <button className={`bg-white py-3 text-center border-0 rounded-lg  cursor-pointer px-2 lg:px-5 font-semibold text-sm lg:text-base focus:outline-none hover:shadow-lg ${classes.button}`}>
                {leftButton.text}
              </button>
            </Link>
            <Link to={rightButton.href}>
              <button className={`flex justify-center items-center text-white py-2 text-center border-0 rounded-sm text-sm lg:text-base cursor-pointer px-2 lg:px-5 font-semibold focus:outline-none ${classes.button}`}>
                <span>{rightButton.text}</span>
                <img
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_right_white_icon.svg"
                  className="hidden md:inline-block ml-2 mb-0"
                  alt="right arrow img"
                  height="26%"
                  width="7%"
                />
              </button>
            </Link>
          </div>
        </div>
        <div className="w-full flex  flex-col items-start lg:w-7/12">
          <img
            alt="market launch"
            src={image}
            className="w-full"
          />
        </div>
      </div>
    </>
  );
};

export default withTranslation()(Ready);
