import React from "react";

import Ready from "../ready/ready";
import Setting from "../setting/setting";
import Subscribe from "../subscribe/subscribe_2";
import TopBanner from "../top_banner/product_top_banner";
import Approach from "../human_approach/approach";
import { useTranslation } from 'react-i18next';

const ProductPlatform = () => {
  const { t } = useTranslation();
  const DataArray = [
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/signal_in_sqaure_icon_with_bg.svg",
      title: `${t('recording_settings')}`,
      description: `${t('recording_protection_is_ensured_by_encryption')}`,
      listItems: [
        `${t('webcam_recording')}`,
        `${t('audio_recording')}`,
        `${t('screen_recording')}`,
        `${t('saving_the_workspace')}`,
        `${t('registration_of_identity_document')}`,
      ],
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/setting_in_sqaure_icon_with_bg.svg",
      title:`${t('lockdown_browser_settings')}`,
      description: `${t('access_to_external_documents_during_the_exam_session')}`,
      listItems: [
        `${t('full_screen_mode_enabled')}`,
        `${t('other_screen_detected')}`,
        `${t('new_tab_inaccessible')}`,
        `${t('browser_cleaned_upstream')}`,
        `${t('cache_cleaned_downstream')}`,
        `${t('printer_disabled')}`,
        `${t('extension_disabled')}`,
        `${t('right_click_disabled')}`,
        `${t('disabled_keyboard_shortcuts')}`,
      ],
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/eye_in_square_icon_with_bg.svg",
      title: `${t('exclusives')}`,
      description: `${t('when_comparing_mereos_to_other_remote_monitoring')}`,
      listItems: [
        `${t('no_downloads')}`,
        `${t('test_accessible_in_1_click')}`,
        `${t('custom_interface')}`,
        `${t('multi_format_assessments')}`,
        `${t('advanced_grade_analysis')}`,
        "Catalogue APIs",
      ],
    },
  ];
  return (
    <>
      <div className={`conatiner font-sans`}>
        <TopBanner
          pageTitle={t('mereos_platform')}
          title={t('your_secure_evaluations_in_1_click')}
          description={t('the_mereos_webApp_solution_is_the_only_secure_assessment')}
          imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/product_top_banner_box_with_items_inside.svg"
          leftButton={{
            text: `${t("free_trials")}`,
            href: "/free-trial",
          }}
          rightButton={{
            text: `Demo >`,
            href: "/demo",
          }}
          page="platform"
        />

        {/* sub Container */}
        <div className={`mx-auto w-11/12 lg:w-5/6 my-20`}>
          <Setting
            pageType={"plateform"}
            title={t('a_unique_web_app')}
            subTitle={t('with_mereos_the_following_monitoring_settings')}
            Data={DataArray}
          />

          <div className="my-20">
            <Approach
              title={t('a_solution_designed_for_candidates')}
              description1={t('mereos_all_in_one_solution')}
              imgSrc="https://d2lxkizvrhu4im.cloudfront.net/images/man_sitting_on_way_with_Laptop.svg"
            />
          </div>
        </div>

        {/* <AutomatedLiveComponent/> */}
        <div className={`mx-auto w-11/12 lg:w-5/6 `}>
          {/* Ready */}
          <div className="my-20">
            <Ready />
          </div>
          {/* <div className="my-20"> */}
          {/* <Analytics /> */}
          {/* </div> */}
        </div>
        <Subscribe />
      </div>
    </>
  );
};

export default ProductPlatform;
