import React, { useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useTranslation } from 'react-i18next';

const Setting = ({ Data, title, subTitle, pageType }) => {
  const breakpoints = useBreakpoint();
  const [DataArray, setDataArray] = useState([...Data]);
  const {t} = useTranslation();
  const [showStatus, setShowStatus] = useState(0);
  return (
    <>
      <div className="">
        <div className="flex justify-center items-center md:justify-between  w-full mx-auto my-10 relative">
          <img
            alt="right star "
            src="https://d2lxkizvrhu4im.cloudfront.net/icons/right_star_icon.svg"
            className="hidden  md:block absolute -left-10 -top-20"
          />
          <div className="text-center space-y-5 mx-auto">
            <div className="text-3xl md:text-5xl font-semibold w-full md:w-2/3 mx-auto text-center">
              {t(title)}
            </div>
            <div className="text-sm md:text-lg text-gray-500 font-medium">
              {t(subTitle)}
            </div>
          </div>
          <img
            alt="left star"
            src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
            className="hidden md:block absolute -right-20 top-0"
          />
        </div>
        <div className="flex flex-col justify-between items-center md:items-start ">
          {DataArray.map((Data, index) => (
            <div
              className={`w-11/12  py-5 md:py-10 flex flex-col items-center md:flex-row  justify-between md:items-start  ${
                breakpoints.md
                  ? ""
                  : index !== DataArray.length - 1
                  ? "border-gray-50 border-b-2"
                  : ""
              }`}
            >
              <div className="w-full md:w-1/3 flex flex-col md:flex-row md:space-x-5">
                <div className="  flex flex-col items-center  ">
                  <img
                    alt="data"
                    src={Data.iconSrc}
                    className="m-0"
                  />
                </div>
                <div className="font-semibold text-lg md:text-2xl text-center md:text-left w-full  md:h-16 md:w-2/3">
                  {t(Data.title)}
                </div>
              </div>
              <div
                className={`text-sm text-gray-500  lg:w-2/3 text-center md:text-left flex flex-col md:flex-row items-start justify-between ${
                  !breakpoints.md
                    ? null
                    : showStatus === index + 1
                      ? "flex"
                      : "hidden"
                }`}
              >
                <div
                  className={`w-full text-sm  md:text-lg
                  md:w-6/12 md:pl-10
                   `}
                >
                  {t(Data.description)}
                </div>
                <div className="flex flex-col w-full md:w-5/12  items-center md:items-start justify-center md:justify-start space-y-3 md:space-y-6 ">
                  {Data.listItems.map((list) => (
                    <div className="flex flex-col md:flex-row  items-center  space-x-2   justify-center md:justify-start">
                      <img
                        alt="data"
                        src="https://d2lxkizvrhu4im.cloudfront.net/icons/tick_icon_yellow.svg"
                        className="my-2 md:m-0"
                      />
                      <span className="m-0 p-0 text-sm md:text-lg font-normal">
                        {t(list)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="md:hidden flex flex-row justify-center md:justify-start text-yellow-500 cursor-pointer">
                {showStatus === index + 1 ? (
                  <div
                    className="flex flex-row items-center space-x-2 text-sm font-semibold my-5"
                    onClick={() => {
                      setShowStatus(0);
                    }}
                  >
                    <span>Hide</span>{" "}
                    <img
                      alt="data"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                      className="m-0 transform rotate-180 -translate-y-0.5 h-2"
                    />
                  </div>
                ) : (
                  <div
                    className="flex flex-row items-center justify-center text-sm font-semibold space-x-2 my-5"
                    onClick={() => {
                      setShowStatus(index + 1);
                    }}
                  >
                    <span>Plus</span>{" "}
                    <img
                      alt="data"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                      className="m-0 h-2"
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Setting;
