import { Link } from "gatsby";
import React from "react";

const Approach = ({title, titleStyle, description1, description2, descriptionStyle, imgSrc, button}) => {
  return (
    <>
      <div className=" flex flex-col-reverse md:flex-row justify-between items-center">
        <div className="hidden md:flex md:w-1/2 ">
          <img
            alt="get started"
            src={imgSrc}
            className="w-10/12"
          />
        </div>
        <div className="flex flex-row md:text-left  md:w-1/2">
          <div className="flex flex-col space-y-3">
            <div
              className="flex justify-center text-2xl md:text-5xl items-center font-semibold text-center md:text-left md:justify-start leading-relax"
              style={titleStyle}
            >
              {title}
            </div>

            <div className="md:hidden md:w-1/2 mx-2">
              <img
                alt="get started"
                src={imgSrc}
              />
            </div>
            <div className="text-gray-500 text-sm md:text-xl text-left space-y-6" style={descriptionStyle}>
              {
                description1 &&
                <p className="leading-normal">
                {description1}
                </p>
              }
              {
                description2 &&
                <p className="mt-10 leading-normal">
                  {description2}
                </p>
              }
            </div>
            {
              button &&
              <Link to={button.href} className="text-yellow-500 flex flex-row text-sm md:text-base font-semibold items-center">
                <div>{button.text}</div>
                <img
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                  alt="arrow icon"
                  className="transform -rotate-90  w-2.5 translate-x-2 m-0 p-0"
                />
              </Link>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Approach;
