import React from "react";
import { Link } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import BannerWrapper from "./banner_wrapper";

import * as classes from "./top_banner.module.scss";

const ProductTopBanner = ({
  pageTitle,
  title,
  description,
  leftButton,
  rightButton,
  imageSrc,
  page,
  pageType
}) => {
  const breakpoints = useBreakpoint();
  return (
    <BannerWrapper>
      <div className={` flex flex-col md:flex-row  justify-center items-center h-full py-16  mx-auto ${classes.wrapper}`}>
        <div className={`flex flex-col lg:flex-row  justify-center items-center w-full `}>
          <div className={`w-10/12 lg:w-6/12 md:mb-32 lg:mb-0 flex flex-col lg:items-end  p-0 h-full `}>
            <div className={`flex flex-col w-full md:w-10/12   space-y-4 md:space-y-5 mx-auto md:mx-0 items-center lg:items-start  mb-10 md:mb-0`}>
              <div className={`text-xs md:text-base font-semibold text-center lg:text-left  opacity-80`}>
                {pageTitle}
              </div>
              <div
                style={{
                  textAlign: pageType === "justify" ? "justify" : "left",
                }}
                className={`font-bold text-3xl md:text-6xl  w-full text-center lg:text-left  `}
              >
                <h1>{title}</h1>
              </div>
              <div
                className={`text-sm md:text-xl  font-medium  text-center  lg:text-left leading-relaxed px-1 md:px-0 w-full md:w-1/2 lg:w-11/12`}
                style={{ textAlign: pageType || "justify"}}
              >
                {`${description}`} 
              </div>
              <div className="w-11/12 md:w-full">
                {
                  leftButton && 
                  <Link to={leftButton.href}>
                    <button className={"w-full md:w-56 text-black bg-white font-semibold text-sm md:text-xl h-12 md:h-16 rounded-md"}>
                      {leftButton.text}
                    </button>
                  </Link>
                }
                {
                  rightButton &&
                  <Link to={rightButton.href}>
                    <button className="w-full md:w-36 h-12 md:h-16 font-semibold text-sm md:text-xl">
                      {rightButton.text}
                    </button>
                  </Link>
                }
              </div>
            </div>
          </div>
          <div className={`w-9/12 lg:w-5/12 bg-cover bg-no-repeat p-0 md:my-0 h-full mx-auto`}>
            <img
              src={imageSrc}
              alt="img banner"
              className={` m-0 p-0 ${page==="platform"?"mt-16":"mt-0"}`}
              width="100%"
              style={
                page === "platform"
                  ? { maxHeight: "480px", maxWidth: "750px" }
                  : { maxHeight: "550px", maxWidth: "750px" }
              }
            />
          </div>
        </div>
      </div>
    </BannerWrapper>
  );
};

export default ProductTopBanner;
